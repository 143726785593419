import { gql } from '@apollo/client'

export default gql`
    mutation runCompareTest(
        $Base64: String
        $Nmi: String!
        $Rates: String
        $InstitutionCode: String, 
        $Discount: String
    ) {
        runCompareTest(
            Base64: $Base64
            Nmi: $Nmi
            Discount: $Discount
            Rates: $Rates
            InstitutionCode: $InstitutionCode
        ) {
            Plans {
                Name
                EstimatedTotalCost
                ComparisonTotalCost
                Url
                Accuraccy
                Status
                Logs
            }
                
            AverageAccuraccy
            Provider
            TotalPlansCompare
            TotalPlansManual
        }
    }
`
