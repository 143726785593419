import gql from 'graphql-tag'

export default gql`
    query partners {
        partners {
            ID
            Name
            Logo
            LastSync
            Sheet
        }
    }
`
