import * as React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import * as BiIcons from 'react-icons/bi'
import { useParams } from 'react-router-dom'
import partners from 'graphql/queries/partners'
import partnerRunSync from 'graphql/mutations/partnerRunSync'
import { Button } from '@material-ui/core'

const PartnerDetails = () => {
    const { PartnerID } = useParams<any>()
    const q = useQuery(partners)

    const [runSheet, mutation] = useMutation(partnerRunSync, {
        refetchQueries: [{ query: partners }]
    })
    if (q.loading) return <></>
    const partner = q.data.partners.find(a => a.ID == PartnerID)
    console.log("q.data.partners", q.data.partners)
    if (!partner) throw new Error("partner not found: "+ PartnerID)
    return (
        <div>
            <BiIcons.BiUserCircle size={35} style={{ display: 'inline-block' }} />
            <h1
                style={{ display: 'inline-block', verticalAlign: 'sub', marginLeft: '12px' }}
            >
                {partner.Name}
            </h1>
            <div>
                {partner.Logo && <div>
                    <img src={partner.Logo} />
                </div> }
                <div>
                    <label>Name: </label>
                    <input type="text" value={partner.Name} />
                </div>
                <div>
                    <label>Last Sync: </label>
                    <input type="text" value={partner.LastSync} />
                </div>
                { mutation.loading ? <div>Running sheet...</div>  : (
                    <div>
                        <label>Sheet: </label>
                        <input type="text" value={partner.Sheet} />
                        { partner.Sheet && partner.Sheet != "" &&
                            <div>
                                <Button onClick={() => window.open(`https://docs.google.com/spreadsheets/d/${partner.Sheet}/edit#gid=0`)}>
                                    Go to sheet
                                </Button>
                                <div>
                                <Button onClick={() => runSheet({variables: {
                                    GroupID: partner.ID
                                }})}>
                                    Run sheet
                                </Button>
                            </div>
                            </div>
                            
                        }

                    </div>
                )}
            </div>
        </div>
    )
}

export default PartnerDetails
