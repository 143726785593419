import * as React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { Home } from './home'
import { EnergyAnalysis } from './graphs/energy'
import { SavingsAnalysis } from './graphs/savings'
import { UsersAnalysis } from './graphs/users'
import { SuperAnalysis } from './graphs/super'

import CarbonAnalysis from 'components/carbonAnalysis'

const Analysis = () => {
    return (
        <div>
            {/* <div>
                <div className={styles.links}>
                    <Link to={`/analysis`} className={styles.link}>
                        Home
                    </Link>
                    <Link to={`/analysis/energy`} className={styles.link}>
                        Energy
                    </Link>
                    <Link to={`/analysis/savings`} className={styles.link}>
                        Savings
                    </Link>
                    <Link to={`/analysis/users`} className={styles.link}>
                        Users
                    </Link>
                </div>
            </div> */}
            <Switch>
                <Route path={`/analysis`} exact component={Home} />
                <Route path={`/analysis/energy`} component={EnergyAnalysis} />
                <Route path={`/analysis/users`} component={UsersAnalysis} />
                <Route path={`/analysis/savings`} component={SavingsAnalysis} />
                <Route path={`/analysis/super`} component={SuperAnalysis} />
                <Route path={`/analysis/carbon`} component={CarbonAnalysis} />
            </Switch>
        </div>
    )
}

export default Analysis
