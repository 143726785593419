
import { useMutation } from "@apollo/client";
import { Button, Input, TextareaAutosize } from "@material-ui/core";
import campaign from "graphql/mutations/campaign";
import { Campaign } from "graphql/types";
import * as React from 'react';

interface Props {
  Campaign: Campaign | undefined;
  setEditCampaign: (Campaign: Campaign | null) => void;
}

export const EditCampaign = ({Campaign, setEditCampaign}: Props) => {

    const [mutateCampaign] = useMutation(campaign, {
        refetchQueries: ['getCampaigns']
    })
    const [newID, setNewID] = React.useState(Campaign ? Campaign.ID : "");
    const [description, setDescription] = React.useState(Campaign ? Campaign.Description : "");
    const [logic, setLogic] = React.useState(Campaign ? Campaign.Logic : "");
    const [doc, setDoc] = React.useState(Campaign ? Campaign.Doc : "");
    const [name, setName] = React.useState(Campaign ? Campaign.Name : "");
    const [priority, setPriority] = React.useState(Campaign ? Campaign.Priority : "");

    return (
        <div style={{padding:"12px"}}>
            <div>
                <label style={{marginRight: "17px"}}>ID</label>
                <span>
                    { Campaign && Campaign.ID != "" ? Campaign.ID : (
                        <Input value={newID}  onChange={(e) => setNewID(e.currentTarget.value)} />
                    )}
                </span>
            </div>
            <div>
                <label>Name</label>
                <div>
                    <Input value={name} onChange={(e) => setName(e.currentTarget.value)}/>
                </div>
            </div>
            <div>
                <label>Priority</label>
                <div>
                    <Input value={priority} onChange={(e) => setPriority(e.currentTarget.value)}/>
                </div>
            </div>
            <div>
                <label>Description</label>
                <div>
                    <TextareaAutosize style={{border: "1px solid #ddd", width: "100%", height: "300px"}} value={description} onChange={(e) => setDescription(e.currentTarget.value)}/>
                </div>
            </div>
            <div>
                <label>Logic</label>
                <div>
                    <TextareaAutosize style={{border: "1px solid #ddd", width: "100%", height: "300px"}}  value={logic} onChange={(e) => setLogic(e.currentTarget.value)}/>
                </div>
            </div>
            <div>
                <label style={{marginRight: "17px"}}>Google Doc Url</label>
                <span>
                    <Input value={doc} onChange={(e) => setDoc(e.currentTarget.value)}/>
                </span>
            </div>
            <Button onClick={async () => {
                
                await mutateCampaign({
                    variables: {
                        CampaignID: Campaign && Campaign.ID ? Campaign.ID : newID,
                        Description: description,
                        Logic: logic,
                        Doc: doc, 
                        Name: name,
                        Priority: priority
                    }
                })
                setEditCampaign(null)

            }}>Save</Button>   
        </div>
    )
}
           