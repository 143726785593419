import gql from 'graphql-tag'

export default gql`
    query adminUsers($PartnerID: String!) {
        adminUsers(PartnerID: $PartnerID) {
            user_id
            created
            email
            lastLogin
            loginCount
            UserHasConsented
            PartnerID
            UserAttribute {    
                key
                value
            }
        }
    }
`
