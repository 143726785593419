import * as React from 'react'
import * as gql from 'graphql/types'
import { useMutation, useQuery } from '@apollo/client'
import * as css from '../User.styles'
import { Button, Checkbox } from '@chakra-ui/react'
import carbonResults from 'graphql/queries/carbonResults'

type Props = {
    user: gql.User
}

const CarbonResults = ({ user }: Props) => {
    const [showAll, setShowAll] = React.useState(false)
    const { loading, error, data, refetch } = useQuery(carbonResults, {
        variables: { adminUserID: user.user_id, all: 'true' },
    })
    if (loading) return <></>
    if (error) {
    
        return <></>
    }

    let results = data.carbonResults
    if (!showAll && results) results = results.filter((a) => a.Current == 'true')

    return (
        <div className={css.container}>
            <div>
                {/* <Checkbox
                    checked={showAll}
                    onChange={() => setShowAll(!showAll)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />{' '} */}
                Show all
            </div>
            <div>
                {results &&
                    results.map((a: gql.CarbonResult) => {
                        const calcs = JSON.parse(a.Calculations!)
                        return (
                            <div className={css.container}>
                                <div className={css.rowLayout}>
                                    <strong>{a.Domain}</strong>
                                    <div>{a.GeneratedAt.substring(0, 19)}</div>
                                    <div>{a.Emissions}</div>
                                    <div>{a.Current}</div>
                                    <div>
                                        {calcs &&
                                            Object.keys(calcs).map((k) => {
                                                return (
                                                    <div>
                                                        {k} - {new String(calcs[k])}
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default CarbonResults
