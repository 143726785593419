import { Button, Input } from "@material-ui/core";
import { Campaign } from "graphql/types"
import * as React from 'react'
import Editor from 'react-simple-wysiwyg';

interface Props {
    Campaign: Campaign 
    handleSubmit: (CampaignID: string, string: string, subject: string, values: Value[]) => Promise<void>
}
export interface Value {
    key: string 
    value: string
}
export const EditCopy = ({Campaign, handleSubmit}: Props) => {

    const [html, setHtml] = React.useState(Campaign.Message);
    const [subject, setSubject] = React.useState(Campaign.Title);
    const [values, setValues] = React.useState<Value[]>([]);

    React.useEffect(() => {
        if (!Campaign.Values) {
            return
        }
        const newValues = JSON.parse(Campaign.Values)
        setValues(newValues)
    }, [])

    function onChange(e) {
        const newHtml: string = e.target.value
        const foundValues = newHtml.matchAll(/{{(.*?)}}/g)
        const newValues: Value[] = [...foundValues].map((value) => {
            return {
                key: value[1],
                value: values.find((v) => v.key === value[1])?.value || ""
            }
        })
        setValues(newValues)
        setHtml(newHtml);
    }

    return (
        <div>
            <Input style={{width: "500px"}} placeholder="Subject" value={subject} onChange={(e) => setSubject(e.currentTarget.value)}/>
            <div style={{marginBottom: "5px", marginTop: "5px", border: "1px solid #ddd", padding: "4px 8px"}}>
                <Editor containerProps={{ style: { resize: 'both' } }} value={html} onChange={onChange} />
            </div>
            {values.map((value, index) => {
                return (
                    <div key={index}>
                        <label style={{width: "200px", marginRight: "15px", fontWeight: "bold"}}>{value.key}</label>
                        <Input value={value.value} onChange={(e) => {
                            const newValues = [...values]
                            newValues[index].value = e.currentTarget.value
                            setValues(newValues)
                        }}/>
                    </div>
                )
            })}
            <Button onClick={() => void handleSubmit(Campaign.ID, html, subject, values)}>Save</Button>
        </div>
    )
}