import * as React from 'react'
import * as gql from 'graphql/types'
import { useMutation, useQuery } from '@apollo/client'
import insights from 'graphql/queries/insights'
import invalidateCurrentInsight from 'graphql/mutations/invalidateCurrentInsight'
import * as css from '../User.styles'
import { Button, Checkbox } from '@chakra-ui/react'
import switchUsers from 'graphql/queries/switchUsers'

type Props = {
    user: gql.User
}

const Switches = ({ user }: Props) => {
    // const [showAll, setShowAll] = React.useState(false)
    const { loading, error, data, refetch } = useQuery(switchUsers, {
        variables: { UserID: user.user_id },
    })
    if (loading) return <></>
    if (error) {
        return <></>
    }
    let switchData = data.switchUsers
    //if (!showAll && insightData) insightData = insightData.filter(a => a.Current=="true")

    return (
        <div className={css.container}>
            {/* <div>
                <Checkbox
                    checked={showAll}
                    onChange={() => setShowAll(!showAll)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />{' '}
                Show all
            </div> */}
            <div>
                {switchData &&
                    switchData.map((a: gql.SwitchUser) => {
                        return (
                            <div className={css.container}>
                                <div className={css.rowLayout}>
                                    <strong>{a.Type}</strong>
                                    <div>{a.Date}</div>
                                    <div>{a.Status}</div>
                                    <div>{a.Provider}</div>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default Switches
