import * as React from 'react'
import * as gql from 'graphql/types'
import * as styles from './User.styles'
import MuiAccordion from '@material-ui/core/Accordion'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { useMutation } from '@apollo/client'
import adminUserAttribute from 'graphql/mutations/adminUserAttribute'
import Insights from './insights'
import { Dialog, Input } from '@material-ui/core'
import UserBehavior from './userBehavior'
import CarbonResults from './carbon'
import Switches from './switches'

type UserDetailsContentProps = {
    user: gql.User
    userAttributes: gql.UserAttribute[]
    refetch: () => void
}

type UserAttributeItemProps = {
    item: gql.UserAttribute
    userID: string
}

const UserAttribute = ({ item, userID }: UserAttributeItemProps) => {
    const [editAttribute] = useMutation(adminUserAttribute, {
        onError: () => alert('THIS DIDNT WORK YO! - refresh and try again'),
    })
    const [editWindow, setEditWindow] = React.useState(false)
    const [value, setValue] = React.useState(item.value!)
    const result = item.key!.replace(/([A-Z])/g, ' $1')
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    const handleBlur = async () => {
        editAttribute({
            variables: {
                UserID: userID,
                AttributeKey: item.key!,
                AttributeValue: value,
            },
        })
        setEditWindow(false)
    }
    return (
        <div className={styles.attribute} onClick={() => setEditWindow(true)}>
            <label className={styles.attributeLabel}>{finalResult}</label>
            {editWindow ? (
                <span className={styles.attributeValue}>
                    <Input
                        value={value}
                        onBlur={() => handleBlur()}
                        onChange={(ev) => setValue(ev.target.value)}
                    />
                </span>
            ) : (
                <span className={styles.attributeValue}>{value}</span>
            )}
        </div>
    )
}
const UserDetailsContent = ({ user, userAttributes, refetch }: UserDetailsContentProps) => {
    const [expanded, setExpanded] = React.useState<Array<string>>([])
    const [groupsModal, setGroupsModal] = React.useState(false)
    const [editAttribute] = useMutation(adminUserAttribute, {
        onError: () => alert('THIS DIDNT WORK YO! - refresh and try again'),
    })
    if (!user) return <div>Loading</div>
    if (!userAttributes) return <div>Loading</div>
    const Accordion = withStyles(styles.accordianStyles)(MuiAccordion)
    const AccordionSummary = withStyles(styles.AccordionSummaryStyles)(MuiAccordionSummary)
    const AccordionDetails = withStyles(styles.AccordionDetailStyles)(MuiAccordionDetails)

    const handleChange = (panel) => (event, newExpanded) => {
        if (expanded.indexOf(panel) > -1) {
            setExpanded(expanded.filter((a) => a != panel))
        } else {
            setExpanded([...expanded, panel])
        }
    }

    const makeTestUser = async (id: string, state: string) => {
        await editAttribute({
            variables: {
                UserID: id,
                AttributeKey: "TestUser",
                AttributeValue: state,
            },
        })
        setGroupsModal(false)
        refetch()
    }
    return (
        <div>
            <div className={styles.headerContainer}>
                <div className={styles.email}>{user.email}</div>
                {user.source && user.source !== null && (
                    <div className={styles.source}>{user.source}</div>
                )}
                <div onClick={() => setGroupsModal(true)} className={styles.group}>
                    {user.PartnerID ? user.PartnerID : "NO PARTNER"}
                </div>
                {userAttributes.find((a) => a.key === 'TestUser')?.value === 'true' && (
                    <div className={styles.testGroup}>TEST USER</div>
                )}
                <Dialog  open={groupsModal} onClose={() => setGroupsModal(false)}>
                    <div  className={styles.groupContainer}>
                        <h2>Add user to test group?</h2>
                        <div className={styles.groupContainer}>
                            <div
                                className={styles.groupItem}
                                onClick={() => makeTestUser(user.user_id, "true")}
                            >Yes</div>
                            <div
                                className={styles.groupItem}
                                onClick={() => makeTestUser(user.user_id, "false")}
                            >No</div>
                        </div>
                    </div>
                </Dialog>
            </div>
            <Accordion
                square
                expanded={expanded.indexOf('panel1') > -1}
                onChange={handleChange('panel1')}
            >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <h2>Identifiers</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <div>
                            <div className={styles.attribute}>
                                <label className={styles.attributeLabel}>Auth0</label>
                                <span className={styles.attributeValue}>{user.user_id}</span>
                            </div>
                        </div>
                        <div>
                            <div className={styles.attribute}>
                                <label className={styles.attributeLabel}>Yodlee</label>
                                <span className={styles.attributeValue}>{user.yodlee_user_id}</span>
                            </div>
                        </div>
                        <div>
                            <div className={styles.attribute}>
                                <label className={styles.attributeLabel}>Avarni</label>
                                <span className={styles.attributeValue}>{user.avarni_user_id}</span>
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion
                square
                expanded={expanded.indexOf('panel2') > -1}
                onChange={handleChange('panel2')}
            >
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <h2>Attributes</h2>
                </AccordionSummary>
                <AccordionDetails>
                    {userAttributes.map((a) => (
                        <UserAttribute key={a.key!} item={a} userID={user.user_id!} />
                    ))}
                </AccordionDetails>
            </Accordion>
            <Accordion
                square
                expanded={expanded.indexOf('panel6') > -1}
                onChange={handleChange('panel6')}
            >
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                    <h2>Insights</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <Insights user={user} />
                </AccordionDetails>
            </Accordion>
            <Accordion
                square
                expanded={expanded.indexOf('panel16') > -1}
                onChange={handleChange('panel16')}
            >
                <AccordionSummary aria-controls="panel16d-content" id="panel16d-header">
                    <h2>User Behavior</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <UserBehavior user={user} />
                </AccordionDetails>
            </Accordion>
            <Accordion
                square
                expanded={expanded.indexOf('panel70') > -1}
                onChange={handleChange('panel70')}
            >
                <AccordionSummary aria-controls="panel70d-content" id="panel70d-header">
                    <h2>Carbon Results</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <CarbonResults user={user} />
                </AccordionDetails>
            </Accordion>
            <Accordion
                square
                expanded={expanded.indexOf('panel75') > -1}
                onChange={handleChange('panel75')}
            >
                <AccordionSummary aria-controls="panel75d-content" id="panel75d-header">
                    <h2>Switches</h2>
                </AccordionSummary>
                <AccordionDetails>
                    <Switches user={user} />
                </AccordionDetails>
            </Accordion>
            <Accordion
                square
                expanded={expanded.indexOf('panel33') > -1}
                onChange={handleChange('panel33')}
            >
                <AccordionSummary aria-controls="panel33d-content" id="panel33d-header">
                    <h2>Campaigns</h2>
                </AccordionSummary>
                <AccordionDetails>
                    {userAttributes.filter(a => a.key.includes('CAMPAIGN:')).map((a) => (
                        <UserAttribute key={a.key!} item={a} userID={user.user_id!} />
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default UserDetailsContent
