import gql from 'graphql-tag'

export default gql`
    query users($user_id: String) {
        users(user_id: $user_id) {
            items {
                user_id
                created
                yodlee_user_id
                avarni_user_id
                uno_user_id
                email
                lastLogin
                loginCount
                PushToken
                group
                version
                UserHasConsented
                userGroup
                PartnerID
            }
        }
    }
`
