import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Button, MenuItem, Select, TextField, Dialog, DialogContent } from '@material-ui/core'

import * as React from 'react'
import * as styles from '../styles'
import * as gql from '../../../graphql/types'
import getCampaignEvents from 'graphql/queries/getCampaignEvents'
import MaterialTable, { Icons } from 'material-table'
import { tableIcons } from 'components/common/MaterialIcons'
import campaignDraftEvent from 'graphql/queries/campaignDraftEvent'
import sendTestCampaignEmail from 'graphql/queries/sendTestCampaignEmail'
import campaign from 'graphql/mutations/campaign'
import { EditCopy } from './edit'
import { EditValues } from './values'

 type Props = {
    Campaign: gql.Campaign
    refetchCampaigns: ()=>void
    setEditCampaign: (Campaign: gql.Campaign | null) => void
 }

const CampaignDetails = ({Campaign, refetchCampaigns, setEditCampaign}: Props) => {
    const { loading, error, data, refetch } = useQuery(getCampaignEvents, { 
        variables: {
            CampaignID: Campaign.ID
        },
    })
    const [testEmail] = useMutation(sendTestCampaignEmail, { 
        variables: {
            CampaignID: Campaign.ID
        },
    })
    const [mutateCampaign] = useMutation(campaign)
    const [runGenerateDraft, draftResult] = useLazyQuery(campaignDraftEvent)
    const [draftLoading, setDraftLoading] = React.useState<boolean>(false)
    const [openModal, setOpenModal] = React.useState<string>('')
    const [openEditModal, setOpenEditModal] = React.useState<gql.Campaign | null>(null)
    if (loading) return <div>Loading</div>
    if (error) return <div>failure {console.error(error)} </div>
    if (!data.getCampaignEvents) return <div>Loading</div>
    const generateDraft = async (id: string) => {
        setDraftLoading(true)
        await runGenerateDraft({
            variables: {
                CampaignID: id,
            },
        })
        
        setDraftLoading(false)
        
    }
    const processCampaign = async () => {
        await mutateCampaign({
            variables: {
                CampaignID: Campaign.ID, 
                Status: 'SendOneOffDraft'
            }
        })

        refetch()
        setOpenModal("")
    }
    const testCampaign = async () => {
        await mutateCampaign({
            variables: {
                CampaignID: Campaign.ID, 
                Status: 'SendOneOffDraftTest'
            }
        })
        refetch()
        setOpenModal("")
    }
    const updateStatus = async (campaignID, status) => {
        await mutateCampaign({
            variables: {
                CampaignID: campaignID, 
                Status: status
            }
        })
        refetchCampaigns()
    }
    const updateCopy = async (campaignID, copy, subject, values) => {
        const updateValues = JSON.stringify(values)
        await mutateCampaign({
            variables: {
                CampaignID: campaignID, 
                Copy: copy,
                Subject: subject,
                Values: updateValues
            }
        })
        refetch()
    }
    var events: gql.CampaignEvent[] = data.getCampaignEvents
    if (!events) return <div>Loading</div>
    if (draftResult.error)
        events = [ {
            AudienceTotal:"0",
            CampaignID: Campaign.ID,
            DateCreated: "ERROR CREATING DRAFT:"+draftResult.error.message,
            ID: "0",
            IsDraft: "true",
            PushTotal: "0",
            Type: "Draft",  
        }, ...events]
    else if (draftResult.data)
        events = [ draftResult.data.campaignDraftEvent, ...events]
    events = events
        .filter(a => a.DateCreated)
        .sort((a, b) => Date.parse(b.DateCreated) - Date.parse(a.DateCreated))
        .slice(0, 10)
        .map((a) => {
        return {
            CampaignID: a.CampaignID,
            Type: a.Type,
            ID: a.ID,
            DateCreated: a.DateCreated.includes("ERROR") ? a.DateCreated : new Date(a.DateCreated).toLocaleDateString(),
            DateUpdated: a.DateUpdated,
            AudienceTotal: a.AudienceTotal,
            PushedUserIDs: a.PushedUserIDs,
            PushTotal: a.PushTotal,
            IsDraft: a.IsDraft,
            Status: a.IsDraft == "true" ? "Draft" : "Completed"
        }
    })
    return (
        <div style={{margin: "13px 8px"}}>
            <div>
                <div className={styles.row}>
                    <div
                        style={{
                            float: 'right',
                            border: '1px solid grey',
                            borderRadius: '5px',
                            padding: '10px 15px',
                            fontSize: '24px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            backgroundColor: 'lightyellow',
                            cursor:"pointer",
                        }}
                        onClick={() => {
                            updateStatus(Campaign.ID, Campaign.Status == 'active' ? 'inactive' : 'active')
                        }}
                    >
                        {Campaign.Status}
                    </div>
                    {!draftLoading && (
                        <div style={{ float: 'right', clear: 'right' }}>
                            <Button onClick={() => generateDraft(Campaign.ID)}>Generate Draft</Button>
                        </div>
                    )}
                    <div style={{ float: 'right', clear: 'right' }}>
                        <Button onClick={() => testEmail()}>TestEmail</Button>
                    </div>
                    <div style={{ float: 'right', clear: 'right' }}>
                        <Button onClick={() => setOpenEditModal(Campaign)}>Edit Copy</Button>
                    </div>
                    <div style={{ float: 'right', clear: 'right' }}>
                        <Button onClick={() => setEditCampaign(Campaign)}>Edit Campaign</Button>
                    </div>
                    <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{Campaign.Name}</div>
                    <div>Created: {Campaign.DateCreated}</div>
                    <div>{Campaign.Description}</div>
                    { Campaign.Doc && <div><a rel="noreferrer" target="_blank" href={Campaign.Doc}>{Campaign.Doc}</a></div> }
                    <div style={{whiteSpace: "pre-wrap", padding:"4px 8px", border: "1px solid #333", "width": "50%"}}>{Campaign.Logic}</div>
                    <br />
                    {events && <div style={{clear: 'both'}}>
                        <MaterialTable
                            icons={tableIcons as Icons}
                            title={'Events'}
                            columns={[
                                { title: 'DateCreated', field: 'DateCreated' },
                                { title: 'AudienceTotal', field: 'AudienceTotal' },
                                { title: 'PushTotal', field: 'PushTotal' },
                                { title: 'Status', field: 'Status' },
                            ]}
                            onRowClick={(event, rowData: any) => {
                                setOpenModal(rowData.PushedUserIDs)
                            }}
                            data={events}
                            options={{
                                toolbar: false,
                                showTitle: true,
                                sorting: false,
                                filtering: false,
                                search: false,
                                pageSize: 50,
                                paging: false
                            }}

                        />
                    </div>}
                    <Dialog
                        open={openModal !== ''}
                        onClose={() => setOpenModal('')}
                    >
                        <DialogContent>
                            <div className={styles.buttons}>
                                <button className={styles.buttonsRun} onClick={() => processCampaign()}>Run</button>
                                <button className={styles.buttonsTest} onClick={() => testCampaign()}>Test</button>
                            </div>
                            <div>Recipients:</div>
                            { openModal.length === 0 ? "NO EMAILS WILL BE SENT!" : (openModal as any).map(a => (
                                <button className={styles.buttonsUser} key={a} onClick={() => window.open("/users/"+a)}>{a}</button>
                            )) }
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={openEditModal !== null}
                        onClose={() => setOpenEditModal(null)}
                    >
                        <DialogContent>
                            <div>
                                { openEditModal && <EditCopy handleSubmit={updateCopy} Campaign={openEditModal} /> }
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default CampaignDetails
